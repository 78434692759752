	function updateSelectedRegion(selectedCountry) {
		var $el = $("#state");
		$el.empty(); // remove old options
		let newOptions = [];

	  // Matches the arrays found in states_helper
		var canadaProvincesOptions = {
		  "Alberta": "Alberta", 
		  "British Columbia": "British Columbia", 
		  "Manitoba": "Manitoba",
		  "New Brunswick": "New Brunswick",
		  "Newfoundland and Labrador": "Newfoundland and Labrador", 
		  "Nova Scotia": "Nova Scotia",
		  "Northwest Territories": "Northwest Territories", 
		  "Nunavut": "Nunavut",
		  "Ontario": "Ontario", 
		  "Prince Edward Island": "Prince Edward Island",
		  "Quebec": "Quebec",
		  "Saskatchewan": "Saskatchewan", 
		  "Yukon": "Yukon"
		};

		var usaStatesOptions = {
		  "Alaska": "Alaska",
		  "Alabama": "Alabama", 
		  "Arkansas": "Arkansas",
		  "Arizona": "Arizona", 
		  "California": "California", 
		  "Colorado": "Colorado", 
		  "Connecticut": "Connecticut", 
		  "District of Columbia": "District of Columbia", 
		  "Delaware": "Delaware", 
		  "Florida": "Florida", 
		  "Georgia": "Georgia", 
		  "Hawaii": "Hawaii", 
		  "Iowa": "Iowa", 
		  "Idaho": "Idaho", 
		  "Illinois": "Illinois", 
		  "Indiana": "Indiana", 
		  "Kansas": "Kansas", 
		  "Kentucky": "Kentucky", 
		  "Louisiana": "Louisiana", 
		  "Massachusetts": "Massachusetts", 
		  "Maryland": "Maryland", 
		  "Maine": "Maine", 
		  "Michigan": "Michigan", 
		  "Minnesota": "Minnesota", 
		  "Missouri": "Missouri", 
		  "Mississippi": "Mississippi", 
		  "Montana": "Montana", 
		  "North Carolina": "North Carolina", 
		  "North Dakota": "North Dakota", 
		  "Nebraska": "Nebraska", 
		  "New Hampshire": "New Hampshire", 
		  "New Jersey": "New Jersey", 
		  "New Mexico": "New Mexico", 
		  "Nevada": "Nevada", 
		  "New York": "New York", 
		  "Ohio": "Ohio", 
		  "Oklahoma": "Oklahoma", 
		  "Oregon": "Oregon", 
		  "Pennsylvania": "Pennsylvania", 
		  "Puerto Rico": "Puerto Rico", 
		  "Rhode Island": "Rhode Island", 
		  "South Carolina": "South Carolina", 
		  "South Dakota": "South Dakota", 
		  "Tennessee": "Tennessee", 
		  "Texas": "Texas", 
		  "Utah": "Utah", 
		  "Virginia": "Virginia", 
		  "Vermont": "Vermont", 
		  "Washington": "Washington", 
		  "Wisconsin": "Wisconsin", 
		  "West Virginia": "West Virginia", 
		  "Wyoming": "Wyoming"
		};

	  if (selectedCountry == 'United States'){
	  	$( "#state-handler" ).show();
	  	$( "#provinces-label" ).hide();
	  	$( "#states-label" ).show();
	  	newOptions = usaStatesOptions;
	  } else if (selectedCountry == 'Canada') {
	  	$( "#state-handler" ).show();
	  	$( "#states-label" ).hide();
	  	$( "#provinces-label" ).show();
	  	newOptions = canadaProvincesOptions;
	  } else {
	  	$( "#state-handler" ).hide();
	  }

  	$.each(newOptions, function(key,value) {
		  $el.append($("<option></option>")
		     .attr("value", value).text(key));
		});
	}


    function updateSelectedRegionMobile(selectedCountry) {
    var $el = $("#state-mobile");
    $el.empty(); // remove old options
    let newOptions = [];

    // Matches the arrays found in states_helper
    var canadaProvincesOptions = {
      "Alberta": "Alberta", 
      "British Columbia": "British Columbia", 
      "Manitoba": "Manitoba",
      "New Brunswick": "New Brunswick",
      "Newfoundland and Labrador": "Newfoundland and Labrador", 
      "Nova Scotia": "Nova Scotia",
      "Northwest Territories": "Northwest Territories", 
      "Nunavut": "Nunavut",
      "Ontario": "Ontario", 
      "Prince Edward Island": "Prince Edward Island",
      "Quebec": "Quebec",
      "Saskatchewan": "Saskatchewan", 
      "Yukon": "Yukon"
    };

    var usaStatesOptions = {
      "Alaska": "Alaska",
      "Alabama": "Alabama", 
      "Arkansas": "Arkansas",
      "Arizona": "Arizona", 
      "California": "California", 
      "Colorado": "Colorado", 
      "Connecticut": "Connecticut", 
      "District of Columbia": "District of Columbia", 
      "Delaware": "Delaware", 
      "Florida": "Florida", 
      "Georgia": "Georgia", 
      "Hawaii": "Hawaii", 
      "Iowa": "Iowa", 
      "Idaho": "Idaho", 
      "Illinois": "Illinois", 
      "Indiana": "Indiana", 
      "Kansas": "Kansas", 
      "Kentucky": "Kentucky", 
      "Louisiana": "Louisiana", 
      "Massachusetts": "Massachusetts", 
      "Maryland": "Maryland", 
      "Maine": "Maine", 
      "Michigan": "Michigan", 
      "Minnesota": "Minnesota", 
      "Missouri": "Missouri", 
      "Mississippi": "Mississippi", 
      "Montana": "Montana", 
      "North Carolina": "North Carolina", 
      "North Dakota": "North Dakota", 
      "Nebraska": "Nebraska", 
      "New Hampshire": "New Hampshire", 
      "New Jersey": "New Jersey", 
      "New Mexico": "New Mexico", 
      "Nevada": "Nevada", 
      "New York": "New York", 
      "Ohio": "Ohio", 
      "Oklahoma": "Oklahoma", 
      "Oregon": "Oregon", 
      "Pennsylvania": "Pennsylvania", 
      "Puerto Rico": "Puerto Rico", 
      "Rhode Island": "Rhode Island", 
      "South Carolina": "South Carolina", 
      "South Dakota": "South Dakota", 
      "Tennessee": "Tennessee", 
      "Texas": "Texas", 
      "Utah": "Utah", 
      "Virginia": "Virginia", 
      "Vermont": "Vermont", 
      "Washington": "Washington", 
      "Wisconsin": "Wisconsin", 
      "West Virginia": "West Virginia", 
      "Wyoming": "Wyoming"
    };

    if (selectedCountry == 'United States'){
      $( "#state-handler-mobile" ).show();
      $( "#provinces-label-mobile" ).hide();
      $( "#states-label-mobile" ).show();
      newOptions = usaStatesOptions;
    } else if (selectedCountry == 'Canada') {
      $( "#state-handler-mobile" ).show();
      $( "#states-label-mobile" ).hide();
      $( "#provinces-label-mobile" ).show();
      newOptions = canadaProvincesOptions;
    } else {
      $( "#state-handler-mobile" ).hide();
    }

    $.each(newOptions, function(key,value) {
      $el.append($("<option></option>")
         .attr("value", value).text(key));
    });
  }


	function updateSelectedTeamFormat(selectedFormat) {
		var $el = $("#position");
		$el.empty(); // remove old options
		let newOptions = [];

		var mdOptions = {
		  "Skip": "1",
		  "Vice": "2",
		  // "Alternate": "Alternate",
		  "Coach": "3"
		}

		var standardOptions = {
		  "Fourth": "1",
		  "Third": "2",
		  "Second": "3",
		  "Lead": "4",
		  "Alternate": "5",
		  "Coach": "6"
		}

	  if (selectedFormat == 'mixed_doubles'){
	  	newOptions = mdOptions;
      $( ".skip-checker" ).hide();
	  } else {
	  	newOptions = standardOptions;
      $( ".skip-checker" ).show();
	  }

  	$.each(newOptions, function(key,value) {
		  $el.append($("<option></option>")
		     .attr("value", value).text(key));
		});
	}

function updateDropDown() {
  if ( $('#country').val() == 'United States' ) {
  	$( "#state-handler" ).show();
  	$( "#provinces-label" ).hide();
	  $( "#states-label" ).show();
  } else if ( $('#country').val() == 'Canada' ) {
  	$( "#state-handler" ).show();
  	$( "#states-label" ).hide();
		$( "#provinces-label" ).show();
  } else {
  	$( "#state-handler" ).hide();
  };
}

function updateDropDownMobile() {
  if ( $('#country-mobile').val() == 'United States' ) {
    $( "#state-handler-mobile" ).show();
    $( "#provinces-label-mobile" ).hide();
    $( "#states-label-mobile" ).show();
  } else if ( $('#country-mobile').val() == 'Canada' ) {
    $( "#state-handler-mobile" ).show();
    $( "#states-label-mobile" ).hide();
    $( "#provinces-label-mobile" ).show();
  } else {
    $( "#state-handler-mobile" ).hide();
  };
}

$(document).ready( function() {

	updateDropDown();
  updateDropDownMobile();

	const country = $('#country');
  const countryMobile = $('#country-mobile');
	const teamFormat = $('#teamFormat')

	// console.log(teamFormat)

	country.on('change', function() {		
		let selectedCountry = country.val();
		// console.log(`selectedCountry ${selectedCountry}`)
	  updateSelectedRegion(selectedCountry);
	});

  countryMobile.on('change', function() {   
    let selectedCountry = countryMobile.val();
    // console.log(`selectedCountry ${selectedCountry}`)
    updateSelectedRegionMobile(selectedCountry);
  });

	teamFormat.on('change', function() {		
		let selectedFormat = teamFormat.val();
		// console.log(`selectedCountry ${selectedCountry}`)
	  updateSelectedTeamFormat(selectedFormat);
	});
	
});



