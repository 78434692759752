// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require("jquery")
import "users"
import "chartkick/chart.js"

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

// jquery
import $ from 'jquery';

global.$ = $
global.jQuery = $

require('jquery-ui');

// jquery-ui theme
// require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.css/ );
//require.context('file-loader?name=[path][name].[ext]&context=node_modules/jquery-ui-dist!jquery-ui-dist', true,    /jquery-ui\.theme\.css/ );

$(function(){
  	// $('.sortable').sortable();
  	  $("#sortable").sortable({
	    update: function(e, ui) {	 
	    	console.log($(this));
	    	console.log($(this).sortable('serialize'));
	      Rails.ajax({
	        url: $(this).data("url"),
	        type: "PATCH",
	        data: $(this).sortable('serialize'),
	      });
	      location.reload();
	    }
	  });
});






